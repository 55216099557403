var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mvx-catalog-article",
      class: {
        "mvx-catalog-article--sold-out": _vm.article.isSoldOut,
        "mvx-catalog-article--popular": _vm.article.isPopular,
      },
      on: {
        mouseleave: function ($event) {
          _vm.displayStockInfos = false
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "mvx-catalog-article__media",
          class: { "mvx-catalog-article__media--zoomable": _vm.article.zoom },
          style: _vm.background,
          on: {
            mouseover: function ($event) {
              _vm.displayStockInfos = true
            },
            mouseleave: function ($event) {
              _vm.displayStockInfos = false
            },
            click: function ($event) {
              return _vm.$emit("zoom", _vm.article.zoom)
            },
          },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(!_vm.article.image ? "Pas d'illustration" : "") +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "mvx-catalog-article__headers" }, [
        _vm.article.type
          ? _c("div", { staticClass: "mvx-catalog-article__headers__type" }, [
              _vm._v(_vm._s(_vm.article.type)),
            ])
          : _c("div", [_vm._v(" ")]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "mvx-catalog-article__headers__price",
            class:
              typeof _vm.prices[_vm.article.id] !== "undefined" &&
              _vm.prices[_vm.article.id]
                ? "mvx-catalog-article__headers__price--set"
                : "mvx-catalog-article__headers__price--unset",
          },
          [
            _c(
              "mvx-button",
              {
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.fetchPrice.apply(null, arguments)
                  },
                },
              },
              [
                _vm.loadingPrice
                  ? _c("mvx-loader")
                  : _c("span", [
                      typeof _vm.prices[_vm.article.id] === "undefined"
                        ? _c("span", [_vm._v("Cliquer pour prix UN.")])
                        : !_vm.prices[_vm.article.id]
                        ? _c("span", [_vm._v("n.a.")])
                        : _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.formatMoney(_vm.prices[_vm.article.id])
                              )
                            ),
                          ]),
                    ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.isAlternate
        ? _c(
            "div",
            {
              staticClass: "mvx-catalog-article__back",
              on: {
                click: function ($event) {
                  return _vm.$emit("closeAlternate")
                },
              },
            },
            [
              _c("mvx-icon", [_vm._v("arrow_back")]),
              _vm._v("\n        Retour article en rupture\n    "),
            ],
            1
          )
        : _vm.article.isSoldOut
        ? _c(
            "div",
            {
              staticClass: "mvx-catalog-article__stock",
              on: {
                mouseover: function ($event) {
                  _vm.displayStockInfos = true
                },
              },
            },
            [_vm._v("\n        Article en rupture\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isAlternate && _vm.hasStockInfos
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.displayStockInfos,
                  expression: "displayStockInfos",
                },
              ],
              staticClass: "mvx-catalog-article__stock-infos",
              on: {
                mouseover: function ($event) {
                  _vm.displayStockInfos = true
                },
              },
            },
            [
              _vm.article.expectedAvailabilityDate
                ? _c("p", [
                    _vm._v(
                      "\n            Prochain arrivage le " +
                        _vm._s(
                          _vm.formatDate(_vm.article.expectedAvailabilityDate)
                        ) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.article.alternates && _vm.article.alternates.length > 0
                ? _c(
                    "div",
                    {
                      staticClass:
                        "mvx-catalog-article__stock-infos__alternates",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.article.alternates.length > 1
                              ? "Articles équivalents"
                              : "Article équivalent"
                          ) +
                          " :\n            "
                      ),
                      _c(
                        "ul",
                        _vm._l(_vm.article.alternates, function (alternate) {
                          return _c("li", { key: alternate.id }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.$emit("alternate", alternate)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(alternate.title))]
                            ),
                          ])
                        }),
                        0
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "mvx-catalog-article__body" }, [
        _c("h3", { staticClass: "mvx-catalog-article__body__title" }, [
          _vm._v(
            "\n            " + _vm._s(_vm.article.title) + "\n            "
          ),
          _c(
            "span",
            { staticClass: "mvx-catalog-article__body__title__reference" },
            [
              _vm._v(
                "\n                - " +
                  _vm._s(_vm.article.code) +
                  "\n            "
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mvx-catalog-article__body__action" },
          [
            _c(
              "div",
              {
                staticClass: "mvx-catalog-article__body__action__packaging",
                class: {
                  "mvx-catalog-article__body__action__packaging--invalid-quantity":
                    _vm.invalidQuantity,
                },
              },
              [
                _vm.invalidQuantity
                  ? _c("span", [_vm._v("Qté. invalide"), _c("br")])
                  : _c("span", [
                      _vm._v(_vm._s(_vm.article.packaging)),
                      _c("br"),
                    ]),
                _vm._v(" "),
                _vm.article.orderUnit > 1
                  ? _c("span", [_vm._v("Par " + _vm._s(_vm.article.orderUnit))])
                  : _c("span", [_vm._v("A l'unité")]),
              ]
            ),
            _vm._v(" "),
            _c("mvx-cart-item-quantity", {
              attrs: {
                "article-id": _vm.article.id,
                "item-quantity": _vm.quantity,
                increment: _vm.article.orderUnit,
                tabindex: _vm.index,
              },
              on: {
                invalid: _vm.notifyInvalidQuantity,
                valid: function ($event) {
                  _vm.invalidQuantity = false
                },
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }