var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mvx-category",
      class: _vm.classes,
      on: {
        click: function ($event) {
          return _vm.selectCategory(_vm.category)
        },
        mouseover: function ($event) {
          _vm.active = true
        },
        mouseleave: function ($event) {
          _vm.active = false
        },
      },
    },
    [
      _c("span", { staticClass: "mvx-category__title" }, [
        _c("i", { staticClass: "fas", class: "fa-" + _vm.category.icon }),
        _vm._v("\n        " + _vm._s(_vm.category.name) + "\n        "),
        _c("small", [_vm._v("(" + _vm._s(_vm.nbArticles(_vm.category)) + ")")]),
      ]),
      _vm._v(" "),
      !_vm.isDirect(_vm.category)
        ? _c(
            "div",
            {
              staticClass:
                "mvx-category__subs mdc-chip-set mdc-chip-set--choice",
            },
            _vm._l(_vm.subCategories(_vm.category), function (subcategory) {
              return _c(
                "div",
                {
                  key: "chip" + subcategory.id,
                  staticClass: "mdc-chip mvx-category__subs__sub",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.selectSubcategory(_vm.category, subcategory)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "mdc-chip__ripple" }),
                  _vm._v(" "),
                  _c("span", [
                    _c(
                      "span",
                      {
                        staticClass:
                          "mdc-chip__text mvx-category__subs__sub__label",
                      },
                      [
                        _vm._v(_vm._s(subcategory.name.toLowerCase()) + " "),
                        _c("small", [
                          _vm._v(
                            "(" + _vm._s(_vm.nbArticles(subcategory)) + ")"
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }