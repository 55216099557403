var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mvx-inner-layout",
    { staticClass: "mvx-categories" },
    [
      _c(
        "mvx-grid-container",
        [
          _c(
            "mvx-grid-row",
            [
              _c(
                "mvx-grid-cell",
                { staticClass: "mvx-categories__root" },
                _vm._l(_vm.displayedCategories1, function (category) {
                  return _c("mvx-category", {
                    key: category.id,
                    attrs: { category: category },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("mvx-scroll-top"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }