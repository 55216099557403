var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mdc-layout-grid__cell",
      class: [
        _vm.phoneClass,
        _vm.tabletClass,
        _vm.desktopClass,
        _vm.largedesktopClass,
        _vm.verylargedesktopClass,
      ],
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }