var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mvx-tab",
    {
      staticClass: "mvx-checkout__step",
      attrs: {
        name: _vm.name,
        icon: _vm.icon,
        disabled: _vm.disabled,
        selected: _vm.selected,
      },
    },
    [
      _c(
        "div",
        { staticClass: "mvx-checkout__step__inner" },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "mvx-checkout__step__nav" }, [
        _c(
          "div",
          { staticClass: "mvx-checkout__step__nav__previous" },
          [
            _vm._t("previous", function () {
              return [
                _vm.previous
                  ? _c(
                      "mvx-button",
                      {
                        attrs: {
                          "leading-icon": _vm.previousIcon,
                          primary: false,
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.$emit("previous")
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "mvx-checkout__step__nav__previous__label",
                          },
                          [_vm._v(_vm._s(_vm.previous))]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mvx-checkout__step__nav__next" },
          [
            _c("div", { staticClass: "mvx-checkout__step__nav__next__total" }, [
              _c(
                "span",
                { staticClass: "mvx-checkout__step__nav__next__total__label" },
                [_vm._v("Total")]
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "mvx-checkout__step__nav__next__total__amount" },
                [_vm._v(_vm._s(_vm.total))]
              ),
            ]),
            _vm._v(" "),
            _vm.next
              ? _c(
                  "mvx-button",
                  {
                    attrs: {
                      "trailing-icon": _vm.nextIcon,
                      primary: "true",
                      disabled: _vm.disabled,
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.$emit("next")
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "mvx-checkout__step__nav__next__button" },
                      [_vm._v(_vm._s(_vm.next))]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "mvx-checkout__step__nav__next__button-short",
                      },
                      [_vm._v(_vm._s(_vm.nextShort))]
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }