var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass: "mvx-button",
      class: { "mvx-button--disabled": _vm.disabled },
      attrs: { title: _vm.title },
    },
    [
      _c(
        "button",
        {
          staticClass: "mvx-button mdc-button",
          class: {
            "mdc-button--unelevated": _vm.primary,
            "mvx-button--primary": _vm.primary,
            "mvx-button--secondary": !_vm.primary,
            "mdc-card__action mdc-card__action--button": _vm.card,
            "mvx-button--active": _vm.active,
            "mdc-button--outlined": _vm.bordered,
          },
          attrs: { type: _vm.type, disabled: _vm.disabled },
          on: {
            click: function ($event) {
              return _vm.$emit("click", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "mdc-button__ripple" }),
          _vm._v(" "),
          _vm.leadingIcon
            ? _c("mvx-icon", { staticClass: "mdc-button__icon" }, [
                _vm._v(_vm._s(_vm.leadingIcon)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "mdc-button__label" },
            [_vm._t("default")],
            2
          ),
          _vm._v(" "),
          _vm.trailingIcon
            ? _c(
                "mvx-icon",
                { staticClass: "mdc-button__icon mvx-button__trailing-icon" },
                [_vm._v(_vm._s(_vm.trailingIcon))]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }