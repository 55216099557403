var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "mdc-text-field",
        class: {
          "mdc-text-field--focused mdc-text-field--invalid": _vm.invalid,
          "mdc-text-field--fullwidth": !_vm.icon,
          "mdc-text-field--with-leading-icon": _vm.icon,
        },
      },
      [
        _vm.icon
          ? _c("mvx-icon", { staticClass: "mdc-text-field__icon" }, [
              _vm._v("\n            " + _vm._s(_vm.icon) + "\n        "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("input", {
          staticClass: "mdc-text-field__input",
          attrs: {
            name: _vm.name,
            type: _vm.type,
            placeholder: _vm.label,
            min: _vm.min,
            step: _vm.step,
            required: _vm.required,
            autofocus: _vm.autofocus,
            autocomplete: _vm.autocomplete,
            tabindex: _vm.tabindex,
          },
          domProps: { value: _vm.value },
          on: {
            input: function ($event) {
              return _vm.$emit("input", $event.target.value)
            },
            click: function ($event) {
              return _vm.$emit("click", $event)
            },
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.$emit("enter")
            },
          },
        }),
        _vm._v(" "),
        _vm.icon
          ? _c(
              "label",
              {
                staticClass:
                  "mdc-floating-label mdc-floating-label--float-above-hack",
              },
              [_vm._v("\n            " + _vm._s(_vm.label) + "\n        ")]
            )
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _vm.help
      ? _c("div", { staticClass: "mdc-text-field-helper-line" }, [
          _c(
            "div",
            {
              staticClass:
                "mdc-text-field-helper-text mdc-text-field-helper-text--persistent",
            },
            [_vm._v(_vm._s(_vm.help))]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }