var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mvx-switch" }, [
    _c(
      "div",
      {
        staticClass: "mdc-switch",
        class: { "mdc-switch--checked": _vm.isActive },
        on: {
          click: function ($event) {
            return _vm.toggleActive()
          },
        },
      },
      [
        _c("div", { staticClass: "mdc-switch__track" }),
        _vm._v(" "),
        _c("div", { staticClass: "mdc-switch__thumb-underlay" }, [
          _c("div", { staticClass: "mdc-switch__thumb" }, [
            _c("input", {
              staticClass: "mdc-switch__native-control",
              attrs: { type: "checkbox", id: _vm.id },
              domProps: { checked: _vm.isActive },
            }),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "label",
      { staticClass: "mvx-switch__label", attrs: { for: _vm.id } },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }