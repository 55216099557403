var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    { attrs: { name: "fade" } },
    [
      _c("mvx-button-icon", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.visible,
            expression: "visible",
          },
        ],
        staticClass: "mvx-scroll-top",
        attrs: { icon: "keyboard_arrow_up", primary: true },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.smoothScroll.apply(null, arguments)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }