var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mvx-cart-item-quantity" },
    [
      _c("mvx-button-icon", {
        attrs: {
          icon: "remove_circle",
          primary: "true",
          disabled: _vm.quantity <= 0,
        },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.decrementQuantity.apply(null, arguments)
          },
        },
      }),
      _vm._v(" "),
      _c("mvx-text-field", {
        attrs: {
          label: "",
          autocomplete: "off",
          name: _vm.name,
          type: "number",
          min: "0",
          invalid: _vm.error,
          step: _vm.increment,
          tabindex: _vm.tabindex,
        },
        on: {
          input: _vm.updateQuantity,
          click: function ($event) {
            return $event.target.select()
          },
        },
        model: {
          value: _vm.quantity,
          callback: function ($$v) {
            _vm.quantity = $$v
          },
          expression: "quantity",
        },
      }),
      _vm._v(" "),
      _c("mvx-button-icon", {
        attrs: { icon: "add_circle", primary: "true" },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.incrementQuantity.apply(null, arguments)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }