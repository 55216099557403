var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mvx-inner-layout",
    { staticClass: "mvx-articles" },
    [
      _c(
        "div",
        { staticClass: "mvx-articles__section" },
        [
          _c(
            "mvx-button",
            {
              staticClass: "mvx-articles__section__nav",
              attrs: { "leading-icon": "arrow_left", primary: false },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.backToSections.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n            Catalogue\n        ")]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "mvx-articles__section__title" }, [
            _vm._v(
              '\n            Résultats de recherche "' +
                _vm._s(_vm.keyword) +
                '"\n            '
            ),
            _c("small", [_vm._v("(" + _vm._s(_vm.nbResults) + ")")]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "mvx-grid-container",
        [
          _vm._l(_vm.groupedSearchResults, function (articles, fullCategory) {
            return [
              _c(
                "div",
                { staticClass: "mvx-articles__category" },
                [
                  _c("h3", { staticClass: "mvx-articles__category__title" }, [
                    _c("span", [
                      _vm._v(
                        "\n                        " +
                          _vm._s(fullCategory) +
                          "\n                    "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "mvx-grid-row",
                    [
                      _c(
                        "mvx-grid-cell",
                        { staticClass: "mvx-articles__category__cards" },
                        _vm._l(articles, function (article) {
                          return _c("mvx-catalog-article-with-alternates", {
                            key: article.id,
                            attrs: { article: article, index: article.id },
                            on: {
                              zoom: function ($event) {
                                return _vm.zoom($event)
                              },
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.zoomImage
        ? _c(
            "mvx-modal",
            {
              staticClass: "mvx-articles__zoom",
              attrs: { "has-buttons": false, closable: true },
              on: {
                close: function ($event) {
                  _vm.zoomImage = null
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  alt: "zoom on article",
                  src: _vm.zoomImage,
                  width: "100%",
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("mvx-scroll-top"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }