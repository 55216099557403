var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mvx-frame" },
    [
      _vm.showSidebar
        ? _c(
            "mvx-sidebar",
            {
              attrs: { title: _vm.sidebarTitle, subtitle: _vm.sidebarSubtitle },
              on: {
                open: _vm.openSidebar,
                close: _vm.closeSidebar,
                space: _vm.closeSmall,
              },
            },
            [_vm._t("sidebar")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mdc-drawer-app-content mvx-frame__content" },
        [
          _c(
            "mvx-navbar",
            {
              attrs: { title: _vm.navbarTitle },
              on: { menu: _vm.toggleSidebar },
            },
            [
              _c(
                "template",
                { slot: "titleActions" },
                [_vm._t("titleActions")],
                2
              ),
              _vm._v(" "),
              _vm._t("navbar"),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mdc-drawer-app-content" }, [
            _c("main", { staticClass: "mvx-frame-inner" }, [
              _c(
                "div",
                { staticClass: "mdc-top-app-bar--fixed-adjust" },
                [_vm._t("default")],
                2
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }