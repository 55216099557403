var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mvx-previous-order",
      class: { "mvx-previous-order--tmp": _vm.order.isSent },
    },
    [
      _c(
        "div",
        { staticClass: "mvx-previous-order__content" },
        [
          _c(
            "h3",
            {
              staticClass:
                "mvx-previous-order__content__number mdc-typography--headline5",
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.formatDate(_vm.order.date)) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mvx-previous-order__content__infos" }, [
            _c("div", [
              _c(
                "span",
                {
                  staticClass:
                    "mvx-previous-order__content__infos__info mdc-typography--subtitle2",
                },
                [_vm._v("Statut")]
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(_vm._s(_vm.order.state) + "\n            "),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "span",
                {
                  staticClass:
                    "mvx-previous-order__content__infos__info mdc-typography--subtitle2",
                },
                [_vm._v("Numéro")]
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(_vm._s(_vm.order.number) + "\n            "),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "span",
                {
                  staticClass:
                    "mvx-previous-order__content__infos__info mdc-typography--subtitle2",
                },
                [_vm._v("Total")]
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(
                _vm._s(_vm.formatMoney(_vm.order.amount)) + "\n            "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mvx-previous-order__content__actions" },
            [
              _c(
                "mvx-button",
                {
                  attrs: { primary: "true", card: "true" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.reorder.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                Ajouter la commande au panier\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "mvx-button",
                {
                  staticClass: "mvx-previous-order__content__actions__seemore",
                  attrs: {
                    "trailing-icon": _vm.displayItems
                      ? "arrow_drop_up"
                      : "arrow_drop_down",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.toggleAllItemsDisplayed.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.displayItems ? "Masquer" : "Voir") +
                      " les articles\n                (" +
                      _vm._s(_vm.order.cart.items.length) +
                      ")\n            "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("transition", { attrs: { name: "slideY" } }, [
            _vm.displayItems
              ? _c(
                  "div",
                  { staticClass: "mvx-previous-order__content__items" },
                  _vm._l(_vm.displayed, function (category, categoryIndex) {
                    return _c(
                      "div",
                      [
                        _c(
                          "h3",
                          {
                            staticClass:
                              "mvx-previous-order__content__items__category",
                          },
                          [_vm._v(_vm._s(category.title))]
                        ),
                        _vm._v(" "),
                        _vm._l(category.items, function (item, itemIndex) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              staticClass:
                                "mvx-previous-order__content__items__item",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mvx-previous-order__content__items__item__title",
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(item.quantity) +
                                      " x " +
                                      _vm._s(item.article.title) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              !_vm.canBeAdded(item)
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mvx-previous-order__content__items__item__disabled",
                                      attrs: {
                                        title:
                                          "Cet article n'est plus disponible",
                                      },
                                    },
                                    [_c("mvx-icon", [_vm._v("error")])],
                                    1
                                  )
                                : _c("mvx-cart-item-quantity", {
                                    attrs: {
                                      tabindex:
                                        1000 + categoryIndex * 1000 + itemIndex,
                                      "article-id": item.article.id,
                                      increment: item.quantity,
                                      "item-quantity":
                                        _vm.itemCartQuantity(item),
                                    },
                                    on: {
                                      invalid: function ($event) {
                                        return _vm.notify(
                                          "error",
                                          "Quantité invalide."
                                        )
                                      },
                                    },
                                  }),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }