import { render, staticRenderFns } from "./MvxPreviousOrder.vue?vue&type=template&id=a1bfadfc"
import script from "./MvxPreviousOrder.vue?vue&type=script&lang=js"
export * from "./MvxPreviousOrder.vue?vue&type=script&lang=js"
import style0 from "./MvxPreviousOrder.vue?vue&type=style&index=0&id=a1bfadfc&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports