var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mvx-menu-link",
      class: {
        "mvx-menu-link--outlined": _vm.active && !_vm.selected,
        "mvx-menu-link--selected": _vm.selected,
      },
      attrs: { title: _vm.title },
      on: {
        mouseover: function ($event) {
          _vm.active = true
        },
        mouseleave: function ($event) {
          _vm.active = false
        },
      },
    },
    [
      _c(
        "a",
        {
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.$emit("click", $event)
            },
          },
        },
        [
          _vm.icon
            ? _c("div", [_c("mvx-icon", [_vm._v(_vm._s(_vm.icon))])], 1)
            : _vm._e(),
          _vm._v(" "),
          _vm._t("default"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }