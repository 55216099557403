var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mvx-tooltip" }, [
    _c(
      "div",
      { ref: "tooltipTarget", staticClass: "mvx-tooltip__target" },
      [_vm._t("default")],
      2
    ),
    _vm._v(" "),
    _c("div", { ref: "tooltipContent", staticClass: "mvx-tooltip__content" }, [
      _vm._v("\n        " + _vm._s(_vm.label) + "\n        "),
      _c("div", {
        staticClass: "mvx-tooltip__content__arrow",
        attrs: { "data-popper-arrow": "" },
      }),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "mvx-tooltip__content__close",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.$emit("close")
            },
          },
        },
        [_c("mvx-icon", [_vm._v("close")])],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }