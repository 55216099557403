var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mvx-inner-layout",
    { staticClass: "mvx-menu" },
    [
      _c(
        "mvx-grid-container",
        [
          _c(
            "mvx-grid-row",
            _vm._l(_vm.pages, function (page) {
              return !page.isInternal &&
                (page.code !== "checkout" || _vm.nbItems > 0)
                ? _c(
                    "mvx-grid-cell",
                    {
                      key: page.code,
                      attrs: { phone: 1, tablet: 1, desktop: 1 / 3 },
                    },
                    [
                      _c(
                        "mvx-menu-link",
                        {
                          attrs: { route: page.route, icon: page.icon },
                          on: {
                            click: function ($event) {
                              return _vm.redirectTo(page.route)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(page.title) +
                              "\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e()
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }