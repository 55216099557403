var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mvx-layout",
    { class: { "mvx-catalog--searching": this.showSearchInputOnScreen } },
    [
      _c(
        "mvx-frame",
        {
          attrs: {
            "navbar-title": _vm.pageTitle,
            "sidebar-title": _vm.user ? _vm.user.name : "",
          },
        },
        [
          _c(
            "template",
            { slot: "navbar" },
            [
              _vm.currentPage.group === "catalog"
                ? [
                    _c(
                      "transition",
                      { attrs: { name: "flip" } },
                      [
                        _vm.showSearchInputOnScreen
                          ? _c("mvx-search", {
                              attrs: { "show-results": "" },
                              on: { reset: _vm.hideSearch },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.showSearchInputOnScreen
                      ? _c("mvx-button-icon", {
                          staticClass: "mdc-top-app-bar__action-item",
                          attrs: { icon: "search", title: "Rechercher" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.showSearch.apply(null, arguments)
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isSearching
                      ? [
                          _vm.showTuto
                            ? _c(
                                "mvx-tooltip",
                                {
                                  attrs: {
                                    placement: "bottom",
                                    label: _vm.focusOnPopularArticles
                                      ? "Cliquer pour voir le catalogue au complet"
                                      : "Cliquer pour restreindre l'affichage aux favoris",
                                  },
                                  on: {
                                    close: function ($event) {
                                      return _vm.hideTuto()
                                    },
                                  },
                                },
                                [
                                  _c("mvx-button-icon", {
                                    staticClass:
                                      "mdc-top-app-bar__action-item navbar__favorite",
                                    attrs: {
                                      icon: _vm.focusOnPopularArticles
                                        ? "favorite"
                                        : "favorite_border",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.toggleFavorite.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c("mvx-button-icon", {
                                staticClass:
                                  "mdc-top-app-bar__action-item navbar__favorite",
                                attrs: {
                                  icon: _vm.focusOnPopularArticles
                                    ? "favorite"
                                    : "favorite_border",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.toggleFavorite.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              }),
                        ]
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.nbItems > 0
                ? _c(
                    "mvx-badge",
                    {
                      staticClass: "navbar__cart",
                      attrs: { value: _vm.nbItems },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "mdc-top-app-bar__action-item",
                          attrs: { title: _vm.checkoutPage.title },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.redirectTo(_vm.checkoutPage.route)
                            },
                          },
                        },
                        [
                          _c("mvx-icon", [
                            _vm._v(_vm._s(_vm.checkoutPage.icon)),
                          ]),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "sidebar" },
            [
              _vm._l(_vm.pages, function (page) {
                return !page.isInternal &&
                  (page.code !== "checkout" || _vm.nbItems > 0)
                  ? _c(
                      "a",
                      {
                        key: page.code,
                        staticClass: "mdc-list-item",
                        class: {
                          "mdc-list-item--activated":
                            _vm.currentPage.group === page.group,
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.redirectTo(page.route)
                          },
                        },
                      },
                      [
                        _c(
                          "mvx-icon",
                          { staticClass: "mdc-list-item__graphic" },
                          [_vm._v(_vm._s(page.icon))]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "mdc-list-item__text" }, [
                          _vm._v(_vm._s(page.title)),
                        ]),
                      ],
                      1
                    )
                  : _vm._e()
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "mdc-list-item",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.showAbout.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("mvx-icon", { staticClass: "mdc-list-item__graphic" }, [
                    _vm._v("help_outline"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "mdc-list-item__text" }, [
                    _vm._v("A propos"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("hr", { staticClass: "mdc-list-divider" }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "mdc-list-item",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.showUpdatePasswordModal = true
                    },
                  },
                },
                [
                  _c("mvx-icon", { staticClass: "mdc-list-item__graphic" }, [
                    _vm._v("vpn_key"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "mdc-list-item__text" }, [
                    _vm._v("Modifier mot de passe"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "mdc-list-item",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.logout()
                    },
                  },
                },
                [
                  _c("mvx-icon", { staticClass: "mdc-list-item__graphic" }, [
                    _vm._v("exit_to_app"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "mdc-list-item__text" }, [
                    _vm._v("Se déconnecter"),
                  ]),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _vm._t("default"),
        ],
        2
      ),
      _vm._v(" "),
      _vm.showUpdatePasswordModal
        ? _c(
            "mvx-modal",
            {
              attrs: {
                title: "Modifier mot de passe",
                "button-label": "Enregistrer",
              },
              on: {
                close: function ($event) {
                  _vm.showUpdatePasswordModal = false
                },
                act: _vm.submitUpdatePasswordForm,
              },
            },
            [
              _c(
                "form",
                { attrs: { autocomplete: "off" } },
                [
                  _c("mvx-text-field", {
                    attrs: {
                      label: "Mot de passe actuel",
                      autocomplete: "current-password",
                      type: "password",
                      name: "password",
                      autofocus: true,
                      invalid: _vm.invalidCurrentPassword,
                    },
                    model: {
                      value: _vm.currentPassword,
                      callback: function ($$v) {
                        _vm.currentPassword = $$v
                      },
                      expression: "currentPassword",
                    },
                  }),
                  _vm._v(" "),
                  _c("mvx-text-field", {
                    attrs: {
                      label: "Nouveau mot de passe",
                      autocomplete: "new-password",
                      type: "password",
                      name: "password",
                      invalid: _vm.invalidNewPassword,
                    },
                    model: {
                      value: _vm.newPassword,
                      callback: function ($$v) {
                        _vm.newPassword = $$v
                      },
                      expression: "newPassword",
                    },
                  }),
                  _vm._v(" "),
                  _c("mvx-text-field", {
                    attrs: {
                      label: "Répéter nouveau",
                      autocomplete: "new-password",
                      type: "password",
                      name: "password",
                      invalid: _vm.invalidNewPassword,
                    },
                    on: { enter: _vm.submitUpdatePasswordForm },
                    model: {
                      value: _vm.repeatPassword,
                      callback: function ($$v) {
                        _vm.repeatPassword = $$v
                      },
                      expression: "repeatPassword",
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showAboutModal
        ? _c(
            "mvx-modal",
            {
              staticClass: "mvx-about-modal",
              on: {
                close: function ($event) {
                  _vm.showAboutModal = false
                },
              },
            },
            [
              _c("div", { staticClass: "mvx-logo" }),
              _vm._v(" "),
              _c("p", [
                _vm._v("Application MVWEB version " + _vm._s(_vm.version)),
              ]),
              _vm._v(" "),
              _vm.catalogUpdate
                ? _c("p", [
                    _vm._v(
                      "Mise à jour du catalogue le " +
                        _vm._s(_vm.formatDate(_vm.catalogUpdate))
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "©" +
                    _vm._s(_vm.currentYear) +
                    " Morgan Vernex, tous droits réservés"
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n            Pour toute question ou problème technique, vous pouvez nous contacter par téléphone au\n            "
                ),
                _c(
                  "a",
                  { staticClass: "mvx-link", attrs: { href: _vm.telHref } },
                  [_vm._v(_vm._s(_vm.supportTel))]
                ),
                _vm._v(" ou par email à\n            "),
                _c(
                  "a",
                  { staticClass: "mvx-link", attrs: { href: _vm.mailHref } },
                  [_vm._v(_vm._s(_vm.supportMail))]
                ),
                _vm._v(".\n        "),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }