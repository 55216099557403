var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    { attrs: { name: "flip", mode: "out-in" } },
    [
      !_vm.selectedAlternate
        ? _c("mvx-catalog-article", {
            key: _vm.article.id,
            attrs: { article: _vm.article, index: _vm.index },
            on: {
              alternate: function ($event) {
                _vm.selectedAlternate = $event
              },
              zoom: function ($event) {
                return _vm.$emit("zoom", $event)
              },
            },
          })
        : _c("mvx-catalog-article", {
            key: _vm.selectedAlternate.id,
            attrs: {
              article: _vm.selectedAlternate,
              index: _vm.index,
              "is-alternate": true,
            },
            on: {
              closeAlternate: function ($event) {
                _vm.selectedAlternate = null
              },
              zoom: function ($event) {
                return _vm.$emit("zoom", $event)
              },
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }