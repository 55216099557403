var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mdc-dialog mvx-modal" }, [
    _c("div", { staticClass: "mdc-dialog__container" }, [
      _c("div", { staticClass: "mdc-dialog__surface" }, [
        _vm.title
          ? _c("h2", { staticClass: "mdc-dialog__title" }, [
              _vm._v(
                "\n                " + _vm._s(_vm.title) + "\n            "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mdc-dialog__content" },
          [
            _vm.closable
              ? _c(
                  "button",
                  {
                    staticClass: "mvx-modal__close",
                    attrs: {
                      type: "button",
                      "data-mdc-dialog-action": "close",
                    },
                  },
                  [_c("mvx-icon", [_vm._v("close")])],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._t("default"),
          ],
          2
        ),
        _vm._v(" "),
        _vm.hasButtons
          ? _c(
              "footer",
              { staticClass: "mdc-dialog__actions" },
              [
                _c(
                  "mvx-button",
                  {
                    staticClass: "mdc-dialog__button",
                    attrs: { "data-mdc-dialog-action": "close" },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.buttonLabel ? "Annuler" : "Fermer") +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.buttonLabel
                  ? _c(
                      "mvx-button",
                      {
                        staticClass:
                          "mdc-dialog__button mdc-dialog__button--default",
                        attrs: { primary: "true" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.$emit("act")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.buttonLabel) +
                            "\n                "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mdc-dialog__scrim" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }