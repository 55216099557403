var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mvx-grid-cell", [
    _c(
      "div",
      {
        staticClass: "mvx-checkout-cart-item",
        class: {
          "mvx-checkout-car-item--removed": _vm.removed,
          "mvx-checkout-car-item--soldout": _vm.article.isSoldOut,
          "mvx-checkout-car-item--noprice": !_vm.article.price,
          "mvx-checkout-car-item--notfound": !_vm.article.isFound,
        },
      },
      [
        _c("div", { staticClass: "mvx-checkout-cart-item__info" }, [
          _c("div", {
            staticClass: "mvx-checkout-cart-item__info__image",
            style: _vm.background,
          }),
          _vm._v(" "),
          _c(
            "h3",
            {
              staticClass:
                "mvx-checkout-cart-item__info__title mdc-typography--subtitle2",
            },
            [
              _c(
                "span",
                { staticClass: "mvx-checkout-cart-item__info__title__title" },
                [_vm._v(_vm._s(_vm.article.title))]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "mvx-checkout-cart-item__info__title__reference",
                },
                [
                  _vm._v(
                    "\n                    - " +
                      _vm._s(_vm.article.code) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.article.isSoldOut ||
              !_vm.article.price ||
              !_vm.article.isFound
                ? _c(
                    "small",
                    { staticClass: "mvx-checkout-cart-item__note" },
                    [
                      _c("br"),
                      _vm._v(" "),
                      _c("mvx-icon", [_vm._v("warning")]),
                      _vm._v(" "),
                      !_vm.article.price || !_vm.article.isFound
                        ? _c("span", [
                            _vm._v(
                              "Attention, il n'est pas possible d'acheter cet article"
                            ),
                          ])
                        : _c("span", [
                            _vm._v(
                              "Attention, article actuellement en rupture, pourra être substitué ou supprimé avant livraison"
                            ),
                          ]),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ]),
        _vm._v(" "),
        _vm.article.isFound && _vm.article.price
          ? _c("div", { staticClass: "mvx-checkout-cart-item__choice" }, [
              _c(
                "div",
                { staticClass: "mvx-checkout-cart-item__choice__price" },
                [_vm._v(_vm._s(_vm.formatMoney(_vm.itemPrice)))]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mvx-checkout-cart-item__choice__quantity" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mvx-checkout-cart-item__choice__quantity__packaging",
                      class: {
                        "mvx-checkout-cart-item__choice__quantity__packaging--invalid-quantity":
                          _vm.invalidQuantity,
                      },
                    },
                    [
                      _vm.invalidQuantity
                        ? _c("span", [_vm._v("Qté. invalide,")])
                        : _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.noBreakingSpaces(_vm.article.packaging)
                              ) + ","
                            ),
                          ]),
                      _vm._v(" "),
                      _vm.article.orderUnit > 1
                        ? _c("span", [
                            _vm._v("par " + _vm._s(_vm.article.orderUnit)),
                          ])
                        : _c("span", [_vm._v("à l'unité")]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("mvx-cart-item-quantity", {
                    staticClass:
                      "mvx-checkout-cart-item__choice__quantity__inner",
                    attrs: {
                      "article-id": _vm.article.id,
                      tabindex: _vm.index,
                      increment: _vm.article.orderUnit,
                      "item-quantity": _vm.quantity,
                    },
                    on: {
                      invalid: _vm.notifyInvalidQuantity,
                      valid: function ($event) {
                        _vm.invalidQuantity = false
                      },
                    },
                  }),
                ],
                1
              ),
            ])
          : _c(
              "div",
              {
                staticClass:
                  "mvx-checkout-cart-item__choice mvx-checkout-cart-item__choice--none",
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.article.isFound,
                        expression: "!article.isFound",
                      },
                    ],
                  },
                  [_vm._v("Article indisponible.")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.article.price,
                        expression: "!article.price",
                      },
                    ],
                  },
                  [_vm._v("Prix indisponible.")]
                ),
                _vm._v(" "),
                _c("mvx-button-icon", {
                  attrs: { icon: "remove_shopping_cart", primary: "true" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.removeArticle()
                    },
                  },
                }),
              ],
              1
            ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }