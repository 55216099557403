var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "mdc-text-field mdc-text-field--textarea mdc-text-field--fullwidth",
    },
    [
      _c(
        "textarea",
        {
          staticClass: "mdc-text-field__input",
          attrs: { id: _vm.name, name: _vm.name, rows: "4", cols: "40" },
          on: {
            input: function ($event) {
              return _vm.$emit("input", $event.target.value)
            },
          },
        },
        [_vm._v(_vm._s(_vm.value))]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "mdc-notched-outline" }, [
        _c("div", { staticClass: "mdc-notched-outline__leading" }),
        _vm._v(" "),
        _c("div", { staticClass: "mdc-notched-outline__notch" }, [
          _c(
            "label",
            { staticClass: "mdc-floating-label", attrs: { for: _vm.name } },
            [_vm._v(_vm._s(_vm.label))]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mdc-notched-outline__trailing" }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }