var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentCategory1
    ? _c(
        "mvx-inner-layout",
        { staticClass: "mvx-articles" },
        [
          _c(
            "div",
            { staticClass: "mvx-articles__section" },
            [
              _c(
                "mvx-button",
                {
                  staticClass: "mvx-articles__section__nav",
                  attrs: { "leading-icon": "arrow_left", primary: false },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.backToSections.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n            Rayons\n        ")]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "mvx-articles__section__title" }, [
                _c("i", {
                  staticClass: "fas",
                  class: "fa-" + _vm.currentCategory1.icon,
                }),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "mvx-articles__section__title__level1" },
                  [_vm._v(_vm._s(_vm.currentCategory1.name))]
                ),
                _vm._v(" "),
                _vm.currentCategory2
                  ? _c("span", [
                      _vm._v("> " + _vm._s(_vm.currentCategory2.name)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.currentCategory3
                  ? _c("span", [
                      _vm._v("> " + _vm._s(_vm.currentCategory3.name)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.currentCategory4
                  ? _c("span", [
                      _vm._v("> " + _vm._s(_vm.currentCategory4.name)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    "(" + _vm._s(_vm.nbArticles(_vm.currentCategory)) + ")"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.displayedCategories3.length > 1
                ? _c(
                    "div",
                    { staticClass: "mdc-menu-surface--anchor" },
                    [
                      _c(
                        "mvx-button",
                        {
                          staticClass: "mvx-articles__section__nav",
                          attrs: {
                            "trailing-icon": "filter_list",
                            primary: false,
                            bordered: true,
                            active:
                              _vm.currentCategory3 || _vm.currentCategory4,
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.filtersOpened = !_vm.filtersOpened
                            },
                          },
                        },
                        [_vm._v("\n                Affiner\n            ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mvx-articles__section__filters mdc-menu-surface mdc-menu-surface--fixed",
                          class: {
                            "mdc-menu-surface--open": _vm.filtersOpened,
                          },
                        },
                        [
                          _c(
                            "ul",
                            { staticClass: "mdc-list" },
                            [
                              _vm.currentCategory3
                                ? _c(
                                    "li",
                                    {
                                      staticClass: "mdc-list-item",
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectCategory3(null)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "mdc-list-item__text" },
                                        [_vm._v("Tout voir")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(
                                _vm.displayedCategories3,
                                function (filterCategory3) {
                                  return [
                                    _c(
                                      "li",
                                      {
                                        staticClass: "mdc-list-item",
                                        class: {
                                          "mdc-list-item--selected":
                                            _vm.currentCategory3 &&
                                            filterCategory3.id ===
                                              _vm.currentCategory3.id,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectCategory3(
                                              filterCategory3
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "mdc-list-item__text",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(filterCategory3.name) +
                                                "\n                                "
                                            ),
                                            _c("small", [
                                              _vm._v(
                                                "(" +
                                                  _vm._s(
                                                    _vm.nbArticles(
                                                      filterCategory3
                                                    )
                                                  ) +
                                                  ")"
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.subCategories(filterCategory3).length
                                      ? _vm._l(
                                          _vm.subCategories(filterCategory3),
                                          function (filterCategory4) {
                                            return _c(
                                              "li",
                                              {
                                                staticClass:
                                                  "mvx-articles__section__filters__sub mdc-list-item",
                                                class: {
                                                  "mdc-list-item--selected":
                                                    _vm.currentCategory4 &&
                                                    filterCategory4.id ===
                                                      _vm.currentCategory4.id,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.selectCategory4(
                                                      filterCategory4
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "mdc-list-item__text",
                                                  },
                                                  [
                                                    _c(
                                                      "mvx-icon",
                                                      {
                                                        staticClass:
                                                          "mvx-articles__section__filters__icon",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "subdirectory_arrow_right"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      "\n                                    " +
                                                        _vm._s(
                                                          filterCategory4.name
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                    _c("small", [
                                                      _vm._v(
                                                        "(" +
                                                          _vm._s(
                                                            _vm.nbArticles(
                                                              filterCategory4
                                                            )
                                                          ) +
                                                          ")"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          }
                                        )
                                      : _vm._e(),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "mvx-grid-container",
            [
              _vm.displayedArticlesForCategory(_vm.currentCategory1, 1).length
                ? _c(
                    "div",
                    { staticClass: "mvx-articles__category" },
                    [
                      _c(
                        "mvx-grid-row",
                        [
                          _c(
                            "mvx-grid-cell",
                            { staticClass: "mvx-articles__category__cards" },
                            _vm._l(
                              _vm.displayedArticlesForCategory(
                                _vm.currentCategory1,
                                1
                              ),
                              function (article, articleIndex) {
                                return _c(
                                  "mvx-catalog-article-with-alternates",
                                  {
                                    key: article.id,
                                    attrs: {
                                      article: article,
                                      index:
                                        3 * 100000 +
                                        2 * 10000 +
                                        1000 +
                                        articleIndex,
                                    },
                                    on: {
                                      zoom: function ($event) {
                                        return _vm.zoom($event)
                                      },
                                    },
                                  }
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.displayedArticlesForCategory(_vm.currentCategory2, 2).length
                ? _c(
                    "div",
                    { staticClass: "mvx-articles__category" },
                    [
                      _c(
                        "mvx-grid-row",
                        [
                          _c(
                            "mvx-grid-cell",
                            { staticClass: "mvx-articles__category__cards" },
                            _vm._l(
                              _vm.displayedArticlesForCategory(
                                _vm.currentCategory2,
                                2
                              ),
                              function (article, articleIndex) {
                                return _c(
                                  "mvx-catalog-article-with-alternates",
                                  {
                                    key: article.id,
                                    attrs: {
                                      article: article,
                                      index: 2 * 10000 + 1000 + articleIndex,
                                    },
                                    on: {
                                      zoom: function ($event) {
                                        return _vm.zoom($event)
                                      },
                                    },
                                  }
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(
                _vm.displayedCategories3,
                function (category3, category3Index) {
                  return [
                    _vm.displayedArticlesForCategory(category3, 3).length
                      ? _c(
                          "div",
                          { staticClass: "mvx-articles__category" },
                          [
                            _c(
                              "h3",
                              { staticClass: "mvx-articles__category__title" },
                              [
                                _c("span", [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(category3.name) +
                                      "\n                        "
                                  ),
                                  _c("small", [
                                    _vm._v(
                                      "(" +
                                        _vm._s(_vm.nbArticles(category3)) +
                                        ")"
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "mvx-grid-row",
                              [
                                _c(
                                  "mvx-grid-cell",
                                  {
                                    staticClass:
                                      "mvx-articles__category__cards",
                                  },
                                  _vm._l(
                                    _vm.displayedArticlesForCategory(
                                      category3,
                                      3
                                    ),
                                    function (article, articleIndex) {
                                      return _c(
                                        "mvx-catalog-article-with-alternates",
                                        {
                                          key: article.id,
                                          attrs: {
                                            article: article,
                                            index:
                                              3 * 10000 +
                                              category3Index * 1000 +
                                              articleIndex,
                                          },
                                          on: {
                                            zoom: function ($event) {
                                              return _vm.zoom($event)
                                            },
                                          },
                                        }
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.subCategories(category3).length
                      ? [
                          _vm._l(
                            _vm.subCategories(category3),
                            function (category4, category4Index) {
                              return [
                                _vm.displayedArticlesForCategory(category4, 4)
                                  .length
                                  ? _c(
                                      "div",
                                      { staticClass: "mvx-articles__category" },
                                      [
                                        _c(
                                          "h3",
                                          {
                                            staticClass:
                                              "mvx-articles__category__title",
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(category3.name) +
                                                  " > " +
                                                  _vm._s(category4.name) +
                                                  "\n                                "
                                              ),
                                              _c("small", [
                                                _vm._v(
                                                  "(" +
                                                    _vm._s(
                                                      _vm.nbArticles(category4)
                                                    ) +
                                                    ")"
                                                ),
                                              ]),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "mvx-grid-row",
                                          [
                                            _c(
                                              "mvx-grid-cell",
                                              {
                                                staticClass:
                                                  "mvx-articles__category__cards",
                                              },
                                              _vm._l(
                                                _vm.displayedArticlesForCategory(
                                                  category4,
                                                  4
                                                ),
                                                function (
                                                  article,
                                                  articleIndex
                                                ) {
                                                  return _c(
                                                    "mvx-catalog-article-with-alternates",
                                                    {
                                                      key: article.id,
                                                      attrs: {
                                                        article: article,
                                                        index:
                                                          4 * 10000 +
                                                          category4Index *
                                                            1000 +
                                                          articleIndex,
                                                      },
                                                      on: {
                                                        zoom: function (
                                                          $event
                                                        ) {
                                                          return _vm.zoom(
                                                            $event
                                                          )
                                                        },
                                                      },
                                                    }
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            }
                          ),
                        ]
                      : _vm._e(),
                  ]
                }
              ),
            ],
            2
          ),
          _vm._v(" "),
          _vm.zoomImage
            ? _c(
                "mvx-modal",
                {
                  staticClass: "mvx-articles__zoom",
                  attrs: { "has-buttons": false, closable: true },
                  on: {
                    close: function ($event) {
                      _vm.zoomImage = null
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      alt: "zoom on article",
                      src: _vm.zoomImage,
                      width: "100%",
                    },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("mvx-scroll-top"),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }