var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mvx-search",
      class: { "mvx-search--searching": _vm.isSearching },
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.query,
            expression: "query",
          },
        ],
        staticClass: "mvx-search__input",
        attrs: { placeholder: "Rechercher" },
        domProps: { value: _vm.query },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            _vm.showSuggestions = true
          },
          input: function ($event) {
            if ($event.target.composing) return
            _vm.query = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mvx-search__close" },
        [
          !_vm.isSearchLoading
            ? _c("mvx-button-icon", {
                attrs: { ripple: false, icon: "close" },
                on: { click: _vm.reset },
              })
            : _c("div", { staticClass: "mvx-spinner" }, [
                _vm._v("\n            Chargement...\n        "),
              ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showSuggestions &&
      _vm.isSearching &&
      !_vm.isSearchLoading &&
      _vm.suggestions !== null
        ? _c(
            "ul",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.hideSuggestions,
                  expression: "hideSuggestions",
                },
              ],
              staticClass: "mvx-search__results",
              style: { width: _vm.inputWidth + "px" },
            },
            [
              _vm.suggestions.length > 0
                ? _vm._l(_vm.suggestions, function (suggestion) {
                    return _c(
                      "li",
                      {
                        staticClass: "mvx-search__results__result",
                        on: {
                          click: function ($event) {
                            return _vm.searchKeyword(suggestion.label)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(suggestion.label) +
                            " (" +
                            _vm._s(suggestion.nbResults) +
                            ")\n            "
                        ),
                      ]
                    )
                  })
                : _c("li", { staticClass: "mvx-search__results__noresult" }, [
                    _vm._v("\n            Aucun résultat\n        "),
                  ]),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }