var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mvx-inner-layout",
    { staticClass: "mvx-orders" },
    [
      _c(
        "mvx-grid-container",
        [
          _c("div", { staticClass: "mvx-orders__disclaimer" }, [
            _c("p", [
              _c(
                "a",
                {
                  staticClass: "mvx-link",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.seePopularArticles.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    Voir mes articles préférés au sein du catalogue\n                "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.orders && _vm.orders.length
            ? _c(
                "mvx-grid-row",
                [
                  _c(
                    "mvx-grid-cell",
                    { staticClass: "mvx-orders__cards" },
                    _vm._l(_vm.orders, function (order) {
                      return _c("mvx-previous-order", {
                        key: order.number,
                        attrs: { order: order },
                        on: {
                          reorder: function ($event) {
                            return _vm.reorder($event)
                          },
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _c("div", { staticClass: "mvx-orders__disclaimer" }, [
                _c("p", [_vm._v("Aucune ancienne commande.")]),
              ]),
          _vm._v(" "),
          _c("div", { staticClass: "mvx-orders__disclaimer" }, [
            _c("p", [
              _c(
                "a",
                {
                  staticClass: "mvx-link",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.redirectTo("articles")
                    },
                  },
                },
                [_vm._v("Consultez le catalogue")]
              ),
              _vm._v(
                "\n                pour passer une nouvelle commande.\n            "
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("mvx-scroll-top"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }