var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "aside",
    { staticClass: "mvx-sidebar mdc-drawer mdc-drawer--dismissible" },
    [
      _c("div", { staticClass: "mdc-drawer__header" }, [
        _c("div", {
          staticClass: "mvx-logo",
          on: {
            click: function ($event) {
              return _vm.redirectTo("menu")
            },
          },
        }),
        _vm._v(" "),
        _c("h3", { staticClass: "mvx-sidebar__title mdc-drawer__title" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
        _vm._v(" "),
        _vm.subtitle
          ? _c("h6", { staticClass: "mdc-drawer__subtitle" }, [
              _vm._v("\n            " + _vm._s(_vm.subtitle) + "\n        "),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mdc-drawer__content" }, [
        _c("nav", { staticClass: "mdc-list" }, [_vm._t("default")], 2),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }