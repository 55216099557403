var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "mvx-icon-button mdc-icon-button",
      class: {
        "mdc-card__action mdc-card__action--icon": _vm.card,
        "mvx-icon-button--primary": _vm.primary && !_vm.disabled,
      },
      attrs: { type: _vm.type, disabled: _vm.disabled },
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      _vm.toggle
        ? _c(
            "mvx-icon",
            { staticClass: "mdc-icon-button__icon mdc-icon-button__icon--on" },
            [_vm._v("\n        " + _vm._s(_vm.toggle) + "\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("mvx-icon", { staticClass: "mdc-icon-button__icon" }, [
        _vm._v(_vm._s(_vm.icon)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }