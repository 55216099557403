var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    { staticClass: "mdc-top-app-bar mdc-top-app-bar--fixed mvx-app-bar" },
    [
      _c("div", { staticClass: "mdc-top-app-bar__row" }, [
        _c(
          "section",
          {
            staticClass:
              "mdc-top-app-bar__section mdc-top-app-bar__section--align-start",
          },
          [
            _c(
              "a",
              { staticClass: "mdc-top-app-bar__navigation-icon mvx-burger" },
              [_c("mvx-icon", [_vm._v("menu")])],
              1
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "mdc-top-app-bar__navigation-icon mvx-brand",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.redirectTo("menu")
                  },
                },
              },
              [_c("div", { staticClass: "mvx-logo" })]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "mdc-top-app-bar__title" }, [
              _vm._v(
                "\n                " + _vm._s(_vm.title) + "\n            "
              ),
            ]),
            _vm._v(" "),
            _vm._t("titleActions"),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "section",
          {
            staticClass:
              "mdc-top-app-bar__section mdc-top-app-bar__section--align-end",
          },
          [_vm._t("default")],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }