var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mvx-loader" }, [
    _vm.loadingMessage
      ? _c("div", { staticClass: "mvx-loader__message" }, [
          _vm._v("\n        " + _vm._s(_vm.loadingMessage) + "\n    "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "mvx-loader__progress mdc-linear-progress mdc-linear-progress--indeterminate",
      },
      [
        _c("div", { staticClass: "mdc-linear-progress__buffering-dots" }),
        _vm._v(" "),
        _c("div", { staticClass: "mdc-linear-progress__buffer" }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "mdc-linear-progress__bar mdc-linear-progress__primary-bar",
          },
          [_c("span", { staticClass: "mdc-linear-progress__bar-inner" })]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "mdc-linear-progress__bar mdc-linear-progress__secondary-bar",
          },
          [_c("span", { staticClass: "mdc-linear-progress__bar-inner" })]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }