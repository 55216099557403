var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mvx-text-message mvx-text-message--error" },
    [
      _c(
        "div",
        { staticClass: "mvx-text-message__content" },
        [
          _c("mvx-icon", [_vm._v("error")]),
          _vm._v(" "),
          _c("div", [_vm._t("default")], 2),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }