var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mvx-layout", { staticClass: "mvx-login" }, [
    _c(
      "div",
      { staticClass: "mvx-login__panel" },
      [
        _c(
          "mvx-grid-container",
          [
            _c(
              "mvx-grid-row",
              [_c("mvx-grid-cell", [_c("div", { staticClass: "mvx-logo" })])],
              1
            ),
            _vm._v(" "),
            _c(
              "form",
              [
                _c(
                  "mvx-grid-row",
                  [
                    _c(
                      "mvx-grid-cell",
                      [
                        _c("mvx-text-field", {
                          attrs: {
                            label: "Code client",
                            name: "username",
                            autocomplete: "username",
                            invalid: _vm.invalidUsername,
                            autofocus: true,
                          },
                          model: {
                            value: _vm.username,
                            callback: function ($$v) {
                              _vm.username = $$v
                            },
                            expression: "username",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "mvx-grid-row",
                  [
                    _c(
                      "mvx-grid-cell",
                      [
                        _c("mvx-text-field", {
                          attrs: {
                            label: "Mot de passe",
                            autocomplete: "current-password",
                            type: "password",
                            name: "password",
                            invalid: _vm.invalidPassword,
                          },
                          model: {
                            value: _vm.password,
                            callback: function ($$v) {
                              _vm.password = $$v
                            },
                            expression: "password",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "mvx-grid-row",
                  [
                    _c(
                      "mvx-grid-cell",
                      [
                        _c(
                          "mvx-button",
                          {
                            attrs: { primary: "true" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.authenticate.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                            Se connecter\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "mvx-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                _vm.showResetPasswordModal = true
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                            J'ai oublié mon mot de passe\n                        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.showResetPasswordModal
          ? _c(
              "mvx-modal",
              {
                attrs: {
                  title: "Oubli du mot de passe",
                  "button-label": "Réinitialiser",
                },
                on: {
                  close: function ($event) {
                    _vm.showResetPasswordModal = false
                  },
                  act: _vm.resetPassword,
                },
              },
              [
                _c(
                  "form",
                  { attrs: { autocomplete: "off" } },
                  [
                    _c("p", [
                      _vm._v(
                        "Veuillez saisir votre code client pour obtenir un nouveau mot de passe par email :"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("mvx-text-field", {
                      attrs: {
                        label: "Code client",
                        autocomplete: "username",
                        name: "username",
                        invalid: _vm.invalidUsernameReset,
                        autofocus: true,
                      },
                      on: { enter: _vm.resetPassword },
                      model: {
                        value: _vm.usernameReset,
                        callback: function ($$v) {
                          _vm.usernameReset = $$v
                        },
                        expression: "usernameReset",
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }