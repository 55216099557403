var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "nav",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.navVisible,
            expression: "navVisible",
          },
        ],
        staticClass: "mvx-tab-bar mdc-tab-bar",
        class: { "mvx-tab-bar--fixed": _vm.fixed },
      },
      [
        _c("div", { staticClass: "mdc-tab-scroller" }, [
          _c("div", { staticClass: "mdc-tab-scroller__scroll-area" }, [
            _c(
              "div",
              { staticClass: "mdc-tab-scroller__scroll-content" },
              _vm._l(_vm.tabs, function (tab, index) {
                return _c(
                  "div",
                  {
                    key: tab.id,
                    staticClass: "mvx-tab mdc-tab",
                    class: {
                      "mdc-tab--active": tab.selected,
                      "mvx-tab--disabled": tab.disabled,
                    },
                    attrs: { id: tab.id, tabindex: index },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("click", tab.name)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "mdc-tab__content" }, [
                      _c(
                        "span",
                        { staticClass: "mdc-tab__icon material-icons" },
                        [_vm._v(_vm._s(tab.icon))]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "mdc-tab__text-label" }, [
                        _vm._v(_vm._s(tab.name)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "mdc-tab-indicator",
                        class: { "mdc-tab-indicator--active": tab.selected },
                      },
                      [
                        _c("span", {
                          staticClass:
                            "mdc-tab-indicator__content mdc-tab-indicator__content--underline",
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "mdc-tab__ripple" }),
                  ]
                )
              }),
              0
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mvx-tabs-section-container" },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }