var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mvx-inner-layout",
    { staticClass: "mvx-checkout" },
    [
      !_vm.nbItems && !_vm.sendSuccess
        ? _c("mvx-grid-container", [
            _c("div", { staticClass: "mvx-checkout__disclaimer" }, [
              _c("p", [
                _vm._v("Aucune commande en cours, votre panier est vide."),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mvx-checkout__disclaimer" }, [
              _c("p", [
                _c(
                  "a",
                  {
                    staticClass: "mvx-link",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.redirectTo("articles")
                      },
                    },
                  },
                  [_vm._v("Consultez le catalogue")]
                ),
                _vm._v(
                  "\n                pour passer une nouvelle commande.\n            "
                ),
              ]),
            ]),
          ])
        : _c(
            "div",
            [
              _c(
                "mvx-text-message",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.canPay && false,
                      expression: "!canPay && false",
                    },
                  ],
                },
                [
                  _vm._v(
                    "\n            Votre niveau d'encours ne permet pas de faire cette commande.\n            Veuillez nous contacter pour pouvoir continuer.\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "mvx-text-message",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.cartIsValid,
                      expression: "!cartIsValid",
                    },
                  ],
                },
                [
                  _vm._v(
                    "\n            Cette commande contient des articles qu'il n'est plus possible d'acheter.\n            Veuillez remplacer ou supprimer ces articles pour pouvoir continuer.\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.sendSuccess,
                      expression: "sendSuccess",
                    },
                  ],
                  staticClass: "mvx-checkout__success",
                },
                [
                  _c(
                    "div",
                    { staticClass: "mvx-checkout__success__inner" },
                    [
                      _c("mvx-icon", [_vm._v("check_circle_outline")]),
                      _vm._v(
                        "\n                Commande bien reçue ! Merci pour votre confiance, nos équipes vont désormais préparer votre\n                commande.\n                "
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "mvx-link",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.redirectTo("menu")
                            },
                          },
                        },
                        [_vm._v("Retour au menu")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "mvx-tabbar",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.sendSuccess,
                      expression: "!sendSuccess",
                    },
                  ],
                  attrs: {
                    "nav-visible": _vm.canPay && _vm.cartIsValid,
                    fixed: "true",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.showStep($event)
                    },
                  },
                },
                [
                  _c(
                    "mvx-checkout-step",
                    {
                      attrs: {
                        name: _vm.steps.cart.name,
                        icon: _vm.steps.cart.icon,
                        selected: _vm.currentStep === _vm.steps.cart.name,
                        next: _vm.steps.cart.next,
                        "next-short": _vm.steps.cart.nextShort,
                        "next-icon": _vm.steps.cart.nextIcon,
                        disabled: !_vm.canPay || !_vm.cartIsValid,
                        total: _vm.formatMoney(_vm.orderTotal),
                      },
                      on: {
                        next: function ($event) {
                          return _vm.showStep(_vm.steps.delivery.name)
                        },
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "previous" },
                        [
                          _c(
                            "mvx-button",
                            {
                              attrs: {
                                "leading-icon": _vm.steps.cart.previousIcon,
                                primary: false,
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.redirectTo("articles")
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "mvx-checkout__step__nav__previous__label",
                                },
                                [_vm._v(_vm._s(_vm.steps.cart.previous))]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "mvx-button",
                            {
                              attrs: {
                                "leading-icon": "remove_shopping_cart",
                                primary: false,
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.showEmptyCartConfirmation = true
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "mvx-checkout__step__nav__previous__label",
                                },
                                [_vm._v("Vider panier")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "mvx-grid-container",
                        [
                          _c(
                            "mvx-grid-row",
                            [
                              _c("mvx-grid-cell", [
                                _c(
                                  "p",
                                  { staticClass: "mvx-checkout__legal-prices" },
                                  [
                                    _c("small", [
                                      _vm._v(
                                        "Les prix affichés sont entendus HT."
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm._l(
                            _vm.displayedArticles,
                            function (category, categoryIndex) {
                              return _c(
                                "div",
                                [
                                  _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "mvx-checkout__cart-category",
                                    },
                                    [_vm._v(_vm._s(category.title))]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    category.items,
                                    function (item, itemIndex) {
                                      return _c(
                                        "mvx-grid-row",
                                        { key: item.article.id },
                                        [
                                          _c("mvx-checkout-cart-item", {
                                            attrs: {
                                              quantity: item.quantity,
                                              index:
                                                1000 +
                                                categoryIndex * 1000 +
                                                itemIndex,
                                              article: item.article,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "mvx-checkout-step",
                    {
                      attrs: {
                        name: _vm.steps.delivery.name,
                        icon: _vm.steps.delivery.icon,
                        selected: _vm.currentStep === _vm.steps.delivery.name,
                        previous: _vm.steps.delivery.previous,
                        "previous-icon": _vm.steps.delivery.previousIcon,
                        next: _vm.steps.delivery.next,
                        "next-short": _vm.steps.delivery.nextShort,
                        "next-icon": _vm.steps.delivery.nextIcon,
                        disabled: !_vm.canPay || !_vm.cartIsValid,
                        total: _vm.formatMoney(_vm.orderTotal),
                      },
                      on: {
                        previous: function ($event) {
                          return _vm.showStep(_vm.steps.cart.name)
                        },
                        next: function ($event) {
                          return _vm.showPayment()
                        },
                      },
                    },
                    [
                      _c(
                        "form",
                        [
                          _c(
                            "mvx-grid-container",
                            [
                              _c(
                                "mvx-grid-row",
                                [
                                  _c("mvx-grid-cell", [
                                    _c("p", [
                                      _vm._v(
                                        "\n                                    Veuillez choisir un transporteur pour votre commande\n                                    (les voyages proposés dans la liste sont ceux compatibles avec notre délai minimum de préparation) :\n                                "
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "mvx-grid-row",
                                [
                                  _c(
                                    "mvx-grid-cell",
                                    {
                                      attrs: {
                                        phone: 1,
                                        tablet: 1,
                                        desktop: 2 / 3,
                                        largedesktop: 2 / 3,
                                      },
                                    },
                                    [
                                      _vm.selectedTransport
                                        ? _c(
                                            "mvx-select-field",
                                            {
                                              attrs: {
                                                label: "Transporteur",
                                                name: "transport",
                                                "has-empty": false,
                                              },
                                              model: {
                                                value: _vm.selectedTransport,
                                                callback: function ($$v) {
                                                  _vm.selectedTransport = $$v
                                                },
                                                expression: "selectedTransport",
                                              },
                                            },
                                            _vm._l(
                                              _vm.displayedTransports,
                                              function (transport) {
                                                return _c("mvx-select-option", {
                                                  key: transport.key,
                                                  attrs: {
                                                    label:
                                                      _vm.transportLabel(
                                                        transport
                                                      ),
                                                    value: transport.key,
                                                    selected:
                                                      transport.key ===
                                                      _vm.selectedTransport,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "mvx-grid-cell",
                                    {
                                      attrs: {
                                        phone: 1,
                                        tablet: 1,
                                        desktop: 1 / 3,
                                        largedesktop: 1 / 3,
                                      },
                                    },
                                    [
                                      _c(
                                        "mvx-select-field",
                                        {
                                          attrs: {
                                            label: "FAD / FAV",
                                            name: "fad",
                                            "has-empty": 1,
                                          },
                                          model: {
                                            value: _vm.fad,
                                            callback: function ($$v) {
                                              _vm.fad = $$v
                                            },
                                            expression: "fad",
                                          },
                                        },
                                        [
                                          _c("mvx-select-option", {
                                            attrs: {
                                              label: "FAD",
                                              value: "fad",
                                              selected: _vm.fad === "fad",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("mvx-select-option", {
                                            attrs: {
                                              label: "FAV",
                                              value: "fav",
                                              selected: _vm.fad === "fav",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "mvx-checkout-step",
                    {
                      attrs: {
                        name: _vm.steps.payment.name,
                        icon: _vm.steps.payment.icon,
                        selected: _vm.currentStep === _vm.steps.payment.name,
                        previous: _vm.steps.payment.previous,
                        "previous-icon": _vm.steps.payment.previousIcon,
                        next: _vm.steps.payment.next,
                        "next-short": _vm.steps.payment.nextShort,
                        "next-icon": _vm.steps.payment.nextIcon,
                        disabled:
                          !_vm.canPay ||
                          !_vm.cartIsValid ||
                          !_vm.selectedTransport ||
                          !_vm.hasBeenOnDelivery,
                        total: _vm.formatMoney(_vm.orderTotal),
                      },
                      on: {
                        previous: function ($event) {
                          return _vm.showStep(_vm.steps.delivery.name)
                        },
                        next: _vm.checkout,
                      },
                    },
                    [
                      _c(
                        "form",
                        [
                          _c(
                            "mvx-grid-container",
                            [
                              _c(
                                "mvx-grid-row",
                                [
                                  _c(
                                    "mvx-grid-cell",
                                    [
                                      _vm.hasShippingCost
                                        ? _c("p", [
                                            _vm._v(
                                              "\n                                    Le montant du panier est de " +
                                                _vm._s(
                                                  _vm.formatMoney(_vm.cartTotal)
                                                ) +
                                                ".\n                                "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.chosenTransport
                                        ? _c(
                                            "p",
                                            [
                                              _vm._v(
                                                "\n                                    Vous avez choisi le transporteur\n                                    "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "mvx-transport",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.chosenTransport.name
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(
                                                ".\n                                    "
                                              ),
                                              _vm.hasShippingCost
                                                ? [
                                                    _vm._v(
                                                      "\n                                        Le montant du fret est évalué à " +
                                                        _vm._s(
                                                          _vm.formatMoney(
                                                            _vm.shippingCost
                                                          )
                                                        ) +
                                                        ".\n                                    "
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              "\n                                        La somme de " +
                                                _vm._s(
                                                  _vm.formatMoney(
                                                    _vm.orderTotal
                                                  )
                                                ) +
                                                " sera déduite de votre solde.\n                                    "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          !_vm.hasShippingCost
                                            ? [
                                                _vm._v(
                                                  "\n                                        Ce montant ne tient pas compte du transport.\n                                    "
                                                ),
                                              ]
                                            : _vm._e(),
                                          _vm._v(
                                            "\n                                    Les prix des produit sont susceptibles\n                                    d’être modifiés entre la commande et la facturation.\n                                "
                                          ),
                                        ],
                                        2
                                      ),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v(
                                          "Vous pouvez laisser un message à notre équipe avant de valider la commande :"
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("mvx-textarea-field", {
                                        attrs: {
                                          label: "Commentaire",
                                          name: "comment",
                                        },
                                        model: {
                                          value: _vm.comment,
                                          callback: function ($$v) {
                                            _vm.comment = $$v
                                          },
                                          expression: "comment",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("button", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: false,
                                            expression: "false",
                                          },
                                        ],
                                        ref: "checkoutFormSubmit",
                                        attrs: { type: "submit" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _vm._v(" "),
      _vm.showEmptyCartConfirmation
        ? _c(
            "mvx-modal",
            {
              attrs: { buttonLabel: "Vider" },
              on: {
                close: function ($event) {
                  _vm.showEmptyCartConfirmation = false
                },
                act: _vm.emptyCart,
              },
            },
            [_c("p", [_vm._v("Etes vous sûr de vouloir vider votre panier ?")])]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }