var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mdc-select mvx-select" }, [
    _c("i", { staticClass: "mdc-select__dropdown-icon" }),
    _vm._v(" "),
    _c(
      "select",
      {
        staticClass: "mdc-select__native-control",
        attrs: { name: _vm.name },
        domProps: { value: _vm.value },
        on: {
          change: function ($event) {
            return _vm.$emit("input", $event.target.value)
          },
        },
      },
      [
        _vm.hasEmpty
          ? _c("mvx-select-option", {
              attrs: { value: "", label: _vm.emptyLabel, selected: !_vm.value },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._t("default"),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "label",
      {
        staticClass: "mdc-floating-label",
        class: {
          "mdc-floating-label--float-above-hack":
            _vm.value || (_vm.hasEmpty && !!_vm.emptyLabel),
        },
      },
      [_vm._v("\n        " + _vm._s(_vm.label) + "\n    ")]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "mdc-line-ripple" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }